import Footer from "../common/Footer";
import Menu from "../common/Menu";

export default function Health(){
    return(
        <>
            <Menu/>
            
            
            <section class="container-fluid bannerhealth p-5">
                <div class="row p-5">
                    <div class="col-md-12 text-center p-5 ">
                        <p>&nbsp;</p>
                        <h2 class="text-uppercase display-1">senior care services</h2><br/>
                        <p class="lead">We care like family.</p>
                        <p>&nbsp;</p>
                        
                    </div>
                </div>
            </section>

            <section className="section-padding light-bg text-dark ">
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="font-40 mb-30 text-center dark-text">
                            How can we help?
                        </h1>
                    </div>
                    <div className="col-md-5 mt-sm-30 mt-5">
                        <img
                        src="assets/images/PersonalSupport.jpg"
                        alt=""
                        className="img-responsive"
                        />
                    </div>
                    <div className="col-md-7 dark-text mt-5">
                    
                    <h3 className="text-bold">Passoniate Healthcare Professionals and complete staffing solutions for the organization</h3>
                    <p className="text-justify" >
                        Emphasize your expertise in providing qualified and skilled PSWs, RPNs, and RNs to long-term care homes, ensuring they have a reliable and competent healthcare workforce. Highlight the range of personal support services your PSWs can provide in the residential care facilities, such as assistance with activities of daily living (ADLs), companionship, mobility support, hygiene care, and meal preparation.
                    </p>
                    <h3 className="text-bold">Quality Home care for seniors</h3>
                    <p  className="text-justify">The desire to remain in the comfort of home makes live-in care the ideal choice for seniors who wish to age in place. With the help of our professional live-in caregivers, seniors and elderly people who need around-the-clock support can live at home well into their golden years. For exceptional live-in home care, Toronto seniors turn to our team of compassionate professional</p>
                    </div>
                    
                </div>
                </div>
            </section>

            <section className="section-padding  dark-bg lighten-4">
            <div className="container">
                <div className="text-center mb-80">
                <h2 className="section-title text-uppercase text-light">Nursing services</h2>
                
                </div>
                <div className="brand-dot row mb-3">
                <div className="featured-item col-md-3 seo-service ">
                    <div className="icon">
                    <img
                        className="img-responsive"
                        src="assets/images/catheterization-1566367223.jpg"
                        alt=""
                    />
                    </div>
                    <div className="desc">
                    <h2>Personal care</h2>
                    <p>
                        Our diverse team of personal support workers have the experience and expertise to handle your personal care needs with ease and grace.
                        <br/><br/><br/>
                    </p>
                    <div className="bg-overlay"></div>
                    <p>
                        <a className="learn-more" href="javascript:void(0)"
                        >
                        Our diverse team of personal support workers have the experience and expertise to handle your personal care needs with ease and grace.
                    </a>
                        
                    </p>
                    </div>
                </div>
                <div className="featured-item col-md-3 seo-service">
                    <div className="icon">
                    <img
                        className="img-responsive"
                        src="assets/images/home-care.png"
                        alt=""
                    />
                    </div>
                    <div className="desc">
                    <h2>Companionship</h2>
                    <p>
                        Sometimes, great patient care is all about good company. Consider companionship services to help stave off the negative effects of loneliness and isolation.
                        <br/><br/><br/>
                    </p>
                    <div className="bg-overlay"></div>
                    <p>
                        <a className="learn-more" href="javascript:void(0)">
                            Sometimes, great patient care is all about good company. Consider companionship services to help stave off the negative effects of loneliness and isolation.
                        </a>
                    </p>
                    </div>
                </div>
                <div className="featured-item col-md-3 seo-service">
                    <div className="icon">
                    <img
                        className="img-responsive"
                        src="assets/images/infusion-1566367188.jpg"
                        alt=""
                    />
                    </div>
                    <div className="desc">
                    <h2>24/7 homecare</h2>
                    <p>
                        Around-the-clock homecare provides peace of mind for both the individual and their families. Through help with daily activities, 24/7 homecare plays a crucial role in helping people age in place.
                    </p>
                    <div className="bg-overlay"></div>
                    <p>
                        <a className="learn-more" href="javascript:void(0)"
                        >
                        Around-the-clock homecare provides peace of mind for both the individual and their families. Through help with daily activities, 24/7 homecare plays a crucial role in helping people age in place.
        </a>
                    </p>
                    </div>
                </div>
                <div className="featured-item col-md-3 seo-service">
                    <div className="icon">
                    <img
                        className="img-responsive"
                        src="assets/images/catheterization-1566367223.jpg"
                        alt=""
                    />
                    </div>
                    <div className="desc">
                    <h2>Personal Shopper</h2>
                    <p>
                        We help seniors, disabled or even depressed person by providing them with personal assistance in grocery shopping, walks, laundary, pick up medicines, dog walking,house sitting and more
                        <br/><br/>
                    </p>
                    <div className="bg-overlay"></div>
                    <p>
                        <a className="learn-more" href="javascript:void(0)"
                        >
                        We help seniors, disabled or even depressed person by providing them with personal assistance in grocery shopping, walks, laundary, pick up medicines, dog walking,house sitting and more
        </a>
                    </p>
                    </div>
                </div>
                
                </div>
                
            </div>
            </section>
            <section className="features4 p-5 iconsbox" style={{"background-color": "#f3ecec"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="display-1 text-center text-uppercase">Other Nursing Services</h2>
                        </div>
                        <div className="col-md-6 my-3">
                            <div className="item-wrapper pt-3">
                                <img src="assets/images/nutrition.png" className="img img-fluid" alt="Image" title=""/>
                                <div className="item-content">
                                    <h3 className="display-5 pt-4 pb-2">
                                        Meal Preparation &amp; Nutrition Support
                                    </h3>
                                    <p className="detailp display-7">
                                        Assist with meal planning, preparation, and feeding, ensuring that residents receive nutritious meals and cater to their dietary needs and preferences.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 my-3">
                            <div className="item-wrapper pt-3">
                                <img src="assets/images/solidarity.png" className="img img-fluid" alt="Image" title=""/>
                                <div className="item-content"><h3 className="display-5 pt-4 pb-2">Palliative Care</h3>
                                <p className="detailp display-7">
                                    Provide specialized care and support for individuals with serious illnesses or nearing the end of life, focusing on pain management, emotional support, and enhancing quality of life.</p>
                                    </div></div>
                        </div>
                        <div className="col-md-6 my-3">
                            <div className="item-wrapper pt-3">
                                <img src="assets/images/mental-health.png" className="img img-fluid" alt="Image" title=""/>
                                <div className="item-content">
                                    <h3 className="display-5 pt-4 pb-2">
                                        Alzheimer's and Dementia Care </h3>
                                    <p className="detailp display-7">
                                        Offer specialized care for individuals with Alzheimer's disease or other forms of dementia, including memory care, cognitive stimulation, and creating a safe environment.</p></div></div>
                        </div>
                        <div className="col-md-6 my-3">
                            <div className="item-wrapper pt-3">
                                <img src="assets/images/social-care.png" className="img img-fluid" alt="Image" title="" />
                            <div className="item-content"><h3 className="display-5 pt-4 pb-2">
                                Respite Care</h3><p className="detailp display-7">Provide temporary relief to family caregivers by offering short-term care services for their loved ones, giving them a break from their caregiving responsibilities.</p>
                                </div>
                            </div>
                        </div>
                        </div></div></section>
        
            <section className="section-padding">
            <div className="container">
                <div className="text-center mb-80">
                <h2 className="section-title text-uppercase">Our Staff Check All The Boxes</h2>
                
                </div>
                <div className="row">
                <div className="col-md-3 mb-50">
                    <div
                    className="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div className="icon">
                        <i className="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div className="desc">
                        <h2 className="pt-4">Reference Check</h2>
                    </div>
                    </div>
                </div>
                <div className="col-md-3 mb-50">
                    <div
                    className="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div className="icon">
                        <i className="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div className="desc">
                        <h2 className="pt-4">Certifications</h2>
                    </div>
                    </div>
                </div>
                <div className="col-md-3 mb-50">
                    <div
                    className="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div className="icon">
                        <i className="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div className="desc">
                        <h2 className="pt-4">Vulnerable Screening</h2>
                    </div>
                    </div>
                </div>
                <div className="col-md-3 mb-50">
                    <div
                    className="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div className="icon">
                        <i className="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div className="desc">
                        <h2 className="pt-4">CVOID-19 Protocol</h2>
                    </div>
                    </div>
                </div>
                <div className="col-md-3 mb-50">
                    <div
                    className="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div className="icon">
                        <i className="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div className="desc">
                        <h2 className="pt-4">Skills & Training</h2>
                    </div>
                    </div>
                </div>
                <div className="col-md-3 mb-50">
                    <div
                    className="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div className="icon">
                        <i className="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div class="desc">
                        <h2 class="pt-4">Immunization Records</h2>
                    </div>
                    </div>
                </div>
                <div class="col-md-3 mb-50">
                    <div
                    class="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div class="icon">
                        <i class="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div class="desc">
                        <h2 class="pt-4">First Aid Certifiedk</h2>
                    </div>
                    </div>
                </div>
                <div class="col-md-3 mb-50">
                    <div
                    class="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div class="icon">
                        <i class="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div class="desc">
                        <h2 class="pt-4">Licensing & Registration</h2>
                    </div>
                    </div>
                </div>
                </div>
                
            </div>
            </section>


            <section class="section-padding bg-blue lighten-4">
                <div class="container">
                <div class="text-center mb-80">
                    <h2 class="section-title text-uppercase text-light">The Staff Relief Advantage</h2>
                </div>
                <div class="brand-dot row mb-3">
                    <div class="featured-item col-md-4 seo-service ">
                    <div class="icon">
                        <img
                        class="img-responsive"
                        src="assets/images/catheterization-1566367223.jpg"
                        alt=""
                        />
                    </div>
                    <div class="desc">
                        <h2>Quality Care</h2>
                        <ul>
                            <li>13-step in house staff training program</li>
                            <li>Compassionate and certified workers </li>
                            <li>Seamless integration with your facility care team</li>
                        </ul>
                    </div>
                    </div>
                    <div class="featured-item col-md-4 seo-service ">
                        <div class="icon">
                        <img
                            class="img-responsive"
                            src="assets/images/home-care.png"
                            alt=""
                        />
                        </div>
                        <div class="desc">
                        <h2>Reliable Service</h2>
                        <ul>
                            <li>24/7 customer service </li>
                            <li>In-house medical team  </li>
                            <li>Dedicated Relationship Manager </li>
                        </ul>
                        </div>
                    </div>
                    <div class="featured-item col-md-4 seo-service ">
                        <div class="icon">
                        <img
                            class="img-responsive"
                            src="assets/images/infusion-1566367188.jpg"
                            alt=""
                        />
                        </div>
                        <div class="desc">
                        <h2>Immediate Assistance</h2>
                        <ul>
                            <li>24-hour request turnaround</li>
                            <li>Easy onboarding process </li>
                            <li>    Large, available pool of staffing resources                       </li>
                        </ul>
                        </div>
                    </div>
                
                </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}