import { BrowserRouter as Router,Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import Clean from "./components/pages/Clean";
import Health from "./components/pages/Health";
import Contact from "./components/pages/Contact";
import Carrer from "./components/pages/Carrer";

function App() {
  return (
    <>
    <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/clean" element={<Clean/>}/>
          <Route path="/health" element={<Health/>}/>
          <Route path="/carrers" element={<Carrer/>}/>
          <Route path="/contact" element={<Contact/>}/>

          {/* <Route path="/" element={<Masteruser/>}>
             <Route path="/about" element={<About/>}/>
             <Route path="/contact" element={<Contact/>}/>
             <Route path="/app" element={<Appo/>}/>
          </Route>
          <Route path="/admin" element={<Masteradmin/>}>
             <Route path="" element={<AdminLogin/>}/>
             <Route path="allcat" element={<AllCat/>}/>
             <Route path="addser" element={<AddServ/>}/>             
             <Route path="showser" element={<ShowServ/>}/>
             <Route path="showser/:sid" element={<EditServ/>}/>
             <Route path="book/:id" element={<Book/>}/>
             <Route path="sbrand" element={<SBrand/>}/>
          </Route> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
