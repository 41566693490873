import { useRef, useState } from "react";
import Footer from "../common/Footer";
import Menu from "../common/Menu";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../index.css'
export default function Contact(){
    const form = useRef();
    const [name,setname] = useState("");
    const [email,setemail] = useState("");
    const [website,setwebsite] = useState("");
    const [phone,setphone] = useState("");
    const [message,setmessage] = useState("");
    
    const setXname = (e)=>{
        setname(e.target.value)
    }
    const setXemail = (e)=>{
        setemail(e.target.value)
    }
    const setXphone = (e)=>{
        setphone(e.target.value)
    }
    const setXwebsite = (e)=>{
        setwebsite(e.target.value)
    }
    const setXmessage = (e)=>{
        setmessage(e.target.value)
    }
    const fireemail = (e)=>{        
        e.preventDefault();
        emailjs.sendForm('service_qw2aj7e', 'template_ljrxgrj', form.current, '9GgrSyHkmm6P2xDyT')
        .then((result) => {
            toast.success("For Submitted ! Team will contact you", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
                });
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        setname("");setemail("");setphone("");setwebsite("");setmessage("");
    }
    return(
        <>
            <Menu/>
            
            
            <section className="container-fluid bg-white">
                <div className="row">
                <div className="col-md-12 text-center p-5">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <h2 className="text-left text-dark"><br />Contact Us</h2>
                        </div>
                    </div>
                    </div>

                </div>
                </div>
            </section>
            <section className="container-fluid">
                <div className="row">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6 my-5 py-5 box my-3">
                        <form ref={form} name="contact-form" id="contactForm"  onSubmit={fireemail}>
                        <div className="row">
                            <div className="col-md-6">
                            <div className="input-field">
                                <input type="text" name="name" className="validate" id="name" value={name} onChange={setXname}/>
                                <label for="name">Name</label>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="input-field">
                                <label className="sr-only" for="email">Email</label>
                                <input id="email" type="email" name="email" className="validate" value={email} onChange={setXemail}/>
                                <label for="email" data-error="wrong" data-success="right">Email</label>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                            <div className="input-field">
                                <input id="phone" type="tel" name="phone" className="validate" value={phone} onChange={setXphone}/>
                                <label for="phone">Phone Number</label>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="input-field">
                                <input id="website" type="text" name="website" className="validate" value={website} onChange={setXwebsite}/>
                                <label for="website">Your Website</label>
                            </div>
                            </div>
                        </div>
                        <div className="input-field">
                            <textarea name="message" id="message" className="materialize-textarea" onChange={setXmessage}>{message}</textarea>
                            <label for="message">Message</label>
                        </div>
                        <button type="submit" name="submit"
                            className="waves-effect waves-light btn submit-button text-capitalize mt-30">Send Message</button>
                        </form>
                    </div>
                    <div className="col-md-6 px-5 text-dark text-justify">
                        <div className="text-center">
                        <h2 className="display-5 text-uppercase mt-5">
                            LET US BE YOUR PARTNER IN CARE
                        </h2>
                        </div>
                        <p className="px-4"><span className="text-bold">24 Hour Support & Service:</span> We offer around-the-clock service
                        to our partners. Rest assured knowing your frontline team will be supported and your residents will be
                        safe & cared for by our fully-vetted staff.
                        <br /><br /><span className="text-bold">3-Step Interview Process: </span>Due diligence is a priority. Our screening
                        and interviewing processes guarantee that only the most compassionate and dependable health care
                        professionals join our team.
                        <br /><br /><span className="text-bold">Thorough Training: </span>Every member of our team must successfully
                        complete modules which incorporates your home’s education and adheres to our sector’s standards.
                        <br /><br /><span className="text-bold">Complete & Updated Files:</span> Our employee files & documentation are
                        always kept up-to-date, exceed Ministry standards and are readily available whenever your home may require
                        them.
                        <br /><br /><span className="text-bold">Strengthen Your Team:</span> Our partners have the unique opportunity of
                        hiring from our staff pool of compassionate & dependable health care professionals to help strengthen
                        their internal staffing foundation.
                        </p>

                    </div>
                    </div>
                </div>
                </div>
            </section>

            <Footer/>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                theme="light"
                />
        </>
    )
}