import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

export default function Menu(){

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    let [menu,setMenu] = useState(isMobile?0:1);
    let css = {
        "clear":menu===1?"both":"none",
        "display":menu===1?"block":"inline-block",
        // "position":menu===1?"relative":"none",
    }
    let current=isMobile?0:1;
    let funChanger= ()=>{
        // alert("First"+menu)
        if(menu===0){
            setMenu(1);
        }
        else{
            setMenu(0);
        }
        // alert(menu)
    }
    return(
    <>
        <div className="top-bar dark-bg lighten-2 visible-md visible-lg">
            <div className="container">
                <div className="row">
                <div className="col-md-6">
                    <ul className="list-inline social-top tt-animate btt">
                    <li>
                        <a href="#"><i className="fa fa-facebook"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-twitter"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-tumblr"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-linkedin"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-dribbble"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-instagram"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="fa fa-rss"></i></a>
                    </li>
                    </ul>
                </div>
                <div className="col-md-6 text-right">
                    <ul className="topbar-cta no-margin">
                    <li className="mr-20">
                        <a
                        ><i className="material-icons mr-10">&#xE0B9;</i
                        >info@securecare.ca</a
                        >
                    </li>
                    <li>
                        <a
                        ><i className="material-icons mr-10">&#xE0CD;</i> +1 4372270008</a
                        >
                    </li>
                    </ul>
                </div>
                </div>
            </div>
        </div>

        <header id="header" className="tt-nav">
        <div className="header-sticky light-header">
            <div className="container">
            
            <div id="materialize-menu" className="menuzord">
                <Link  to="/" className="logo-brand">
                <img src="assets/images/blacklogo.PNG" alt="" />
                </Link>

                <Link className="myicon" style={{"display":!isMobile?"none":"inline"}} onClick={funChanger}>
                <span class="dark-blue-text"><i className="fa fa-solid fa-bars"></i></span>
                </Link>
                <ul className="menuzord-menu pull-right" 
                style={{"display":isMobile?"none":"block"}}>
                    <li><Link  to="/">Home</Link></li>
                    <li><Link  to="/health">Health Care</Link></li>
                    <li><Link  to="/clean">Cleaning</Link></li>
                    <li><Link  to="/carrers">Carrers</Link></li>
                    <li><Link  to="/contact">Contact</Link></li>
                </ul>
                {isMobile?
                <ul className="MyMenu" style={{"display":menu===0?"none":"block"}}>
                    <li onClick={funChanger}><Link  to="/">Home</Link></li>
                    <li onClick={funChanger}><Link  to="/health">Health Care</Link></li>
                    <li onClick={funChanger}><Link  to="/clean">Cleaning</Link></li>
                    <li onClick={funChanger}><Link  to="/carrers">Carrers</Link></li>
                    <li onClick={funChanger}><Link  to="/contact">Contact</Link></li>
                </ul>
                :""}
            
                
            </div>
            </div>
        </div>
        </header>
    </>
    )
}

