// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyMenu{
  list-style-type: none;
  clear:both;
  color:#202020;
}
.MyMenu li{
  display: block;
  padding:0.5em;
  font-size:1.2em;
}
.MyMenu li a{  
  color:#202020 !important;

}

.myicon{
    width: 30px;
    height: 46px;
    padding: 12px 0 0;
    display: none;
    float: right;
    text-decoration: none;
    outline: 0;
    font-size:25px;
    color:grey;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,UAAU;EACV,aAAa;AACf;AACA;EACE,cAAc;EACd,aAAa;EACb,eAAe;AACjB;AACA;EACE,wBAAwB;;AAE1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,UAAU;IACV,cAAc;IACd,UAAU;AACd","sourcesContent":[".MyMenu{\n  list-style-type: none;\n  clear:both;\n  color:#202020;\n}\n.MyMenu li{\n  display: block;\n  padding:0.5em;\n  font-size:1.2em;\n}\n.MyMenu li a{  \n  color:#202020 !important;\n\n}\n\n.myicon{\n    width: 30px;\n    height: 46px;\n    padding: 12px 0 0;\n    display: none;\n    float: right;\n    text-decoration: none;\n    outline: 0;\n    font-size:25px;\n    color:grey;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
