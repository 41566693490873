import Footer from "../common/Footer";
import Menu from "../common/Menu";
import { Link } from "react-router-dom";


export default function Home(){
    return(
        <>
            <Menu/>
            
            <section className="container-fluid bannerclean p-5" id="indexbanner">
                <div className="row p-5">
                    <div className="col-md-12 text-center p-5 ">
                        <p>&nbsp;</p>
                        <h2 className="text-uppercase display-1">EMRACE PEACE AND PURITY : SECURE CARE</h2><br/>
                        <p className="lead">Your Path to sparkling homes and heartfelt Care</p>
                        <p>&nbsp;</p>
                        
                    </div>
                </div>
            </section>

            <section className="container-fluid bg-light p-5">
            <div className="row p-5">
                <div className="col-md-12 text-center mb-3">
                <h1 className="text-center display-1">Our Services</h1>
                </div>
                <div className="col-md-12 text-center">
                    <div className="container p-0">
                    <div className="row p-0 m-0">
                        <div className="col-md-6 p-md-5 p-sm-0 p-0">
                        <div className="card m-b-30">
                            <div className="card-body">
                            <div className="xp-social-profile">
                                <div className="xp-social-profile-top">
                                <div className="row">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-6">
                                    <div className="xp-social-profile-avatar text-center">
                                        <img
                                        src="assets/images/homehealth.jpg"
                                        alt="user-profile"
                                        className="rounded-circle img-fluid"
                                        />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="xp-social-profile-middle text-center">
                                <div className="row">
                                    <div className="col-12">
                                    <div className="xp-social-profile-title">
                                        <h2 className="my-3 text-black">Senior Care</h2>
                                    </div>
                                    <div className="xp-social-profile-desc">
                                        <p className="text-muted p-2">
                                        Secure Care is a leading senior care provider in Canada, offering a range of services including personal care, homemaking, nursing care, and companionship
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6  p-md-5 p-sm-0 p-0">
                            <div className="card m-b-30">
                            <div className="card-body">
                                <div className="xp-social-profile">
                                <div className="xp-social-profile-top">
                                    <div className="row">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-6">
                                        <div className="xp-social-profile-avatar text-center">
                                        <img
                                            src="assets/images/homeclearn.jpg"
                                            alt="user-profile"
                                            className="rounded-circle img-fluid"
                                        />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="xp-social-profile-middle text-center">
                                    <div className="row">
                                    <div className="col-12">
                                        <div className="xp-social-profile-title">
                                        <h2 className="my-3 text-black">Cleaning</h2>
                                        </div>
                                        <div className="xp-social-profile-desc">
                                        <p className="text-muted p-2">
                                            Secure Care is your trusted partner for all your cleaning needs! The cleaning can be done for your house, office, hospitals and apartment or anywhere you want
                                        </p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </section>
            <section className="container-fluid bg-dark p-5">
            <div className="row p-5">
                <div className="col-md-12 text-center p-5 ">
                    <p>&nbsp;</p>
                    <h2 className="text-uppercase display-1 text-white">Join Our Team</h2><br/>
                    <p className="lead">Think you have What it takes? Build you Carrer with the fastest growing security company in Canada.</p>

                    <p>&nbsp;</p>
                    <Link className="btn" to="/carrer">Carrer Opporitunities</Link>
                </div>
            </div>
            </section>
          
            <Footer/>
        </>
    );
}