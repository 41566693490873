import { Link } from "react-router-dom";

export default function Footer(){
    return(
    <>
         <footer className="footer footer-four">
      <div className="primary-footer dark-bg lighten-4 text-center">
        <div className="container">
          <a href="#top" className="page-scroll btn-floating btn-large back-top waves-effect waves-light" data-section="#top">
            <i className="material-icons">&#xE316;</i>
          </a>
          <ul className="social-link tt-animate ltr mt-20">
            <li>
              <a href="#"><i className="fa fa-facebook"></i></a>
            </li>
            <li>
              <a href="#"><i className="fa fa-twitter"></i></a>
            </li>
            <li>
              <a href="#"><i className="fa fa-tumblr"></i></a>
            </li>
            <li>
              <a href="#"><i className="fa fa-linkedin"></i></a>
            </li>
          </ul>
          <hr className="mt-15" />
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="footer-logo">
                <img src="assets/images/Securewhitelogo.png" className="img-fluid" alt="" />
              </div>
              <span className="copy-text"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="secondary-footer dark-bg lighten-3 text-center">
        <div className="container">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/clean">Cleaning</Link></li>
            <li><Link to="/heath">Health Care</Link></li>
            <li><Link to="/contact">Carrers</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </div>
    </footer>

    </>
    )
}