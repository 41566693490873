import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import Menu from "../common/Menu";

export default function Clean(){
    return(
        <>
            <Menu/>
            
            <section className="container-fluid bannerclean p-5">
                <div className="row p-5">
                    <div className="col-md-12 text-center p-5 ">
                        <p>&nbsp;</p>
                        <h2 className="text-uppercase display-1">High quality cleaning service</h2><br/>
                        <p className="lead">Keep your Space clean is our job</p>
                        <p>&nbsp;</p>
                        
                    </div>
                </div>
            </section>

            <section className="section-padding light-bg text-dark ">
                <div className="container">
                <div className="row">
                    <div className="col-md-7 dark-text">
                    <h2 className="font-40 mb-30 dark-text">
                        About US
                    </h2>
                    <p className="text-justify" >
                        We understand life gets busy. If cleaning keeps falling off your list of priorities, leave it to our professional, experienced and dedicated team of cleaners in Ontario. Choose our home cleaning services in Toronto and start enjoying more free time.
                    </p>
                    <h3 className="text-bold">The Best cleaning services in Ontario just for you</h3>
                    <p  className="text-justify">Secure care has highly skilled staff and provides you with exceptional service of cleaning your living or working space. It provides the best quality services with being reliable and consistent at it. Each expert works on flexible hours with providing satisfying services to the clients. . Whether you need us for a one-time cleaning project or require ongoing cleaning services, we are flexible and adaptable to accommodate your schedule and provide a seamless experience.</p>
                    </div>
                    <div className="col-md-5 mt-sm-30">
                    <img
                        src="assets/images/secureabout.jpg"
                        alt=""
                        className="img-responsive"
                    />
                    </div>
                </div>
                </div>
            </section>

            <section className="section-padding  dark-bg lighten-4">
            <div className="container">
                <div className="text-center mb-80">
                <h2 className="section-title text-uppercase text-light">What we Offer</h2>
                 <p className="section-sub">
                    Quisque non erat mi. Etiam congue et augue sed tempus. Aenean sed
                    ipsum luctus, scelerisque ipsum nec, iaculis justo. Sed at
                    vestibulum purus, sit amet viverra diam nulla ac nisi rhoncus.
                </p>
                </div>
                <div className="brand-dot row mb-3">
                <div className="featured-item col-md-4 seo-service ">
                    <div className="icon">
                    <img
                        className="img-responsive"
                        src="assets/images/clean1.jpg"
                        alt=""
                    />
                    </div>
                    <div className="desc">
                    <h2>Complete House Cleaning Service</h2>
                    <p>
                        Secure care has prided itself on providing professional and superior approach to house cleaning. Our team of experts provide responsible, dependable, first-rate home cleaning services that will ensure your home is ready to be featured in Better Homes and Gardens. Utilizing detail-clean services performed on a rotating basis, along with our typical thorough cleaning propels Secure Care miles above the competition.


                    </p>
                    <div className="bg-overlay"></div>
                    <p>
                        <a className="learn-more" href="javascript:void(0)"
                        >
                        Secure care has prided itself on providing professional and superior approach to house cleaning. Our team of experts provide responsible, dependable, first-rate home cleaning services that will ensure your home is ready to be featured in Better Homes and Gardens. Utilizing detail-clean services performed on a rotating basis, along with our typical thorough cleaning propels Secure Care miles above the competition.
                    </a>
                        
                    </p>
                    </div>
                </div>
                <div className="featured-item col-md-4 seo-service">
                    <div className="icon">
                    <img
                        className="img-responsive"
                        src="assets/images/clean2.jpg"
                        alt=""
                    />
                    </div>
                    <div className="desc">
                    <h2>Condo and Apartment Cleaning Services</h2>
                    <p>
                        Apartments may attract more bugs and other insects as there are more inhabitants living in a confined area, which is why it is necessary to keep your property clean. The apartments are smaller which is why fewer people are required to complete the job but without compromising on the quality. Entire apartment gets the cleaning without any flaw. Nothing is missed by our professionals and Secure Care will provide the best services to our clients.
                    </p>
                    <div className="bg-overlay"></div>
                    <p>
                        <a className="learn-more" href="javascript:void(0)">
                            Apartments may attract more bugs and other insects as there are more inhabitants living in a confined area, which is why it is necessary to keep your property clean. The apartments are smaller which is why fewer people are required to complete the job but without compromising on the quality. Entire apartment gets the cleaning without any flaw. Nothing is missed by our professionals and Secure Care will provide the best services to our clients.
                        </a>
                    </p>
                    </div>
                </div>
                <div className="featured-item col-md-4 seo-service">
                    <div className="icon">
                    <img
                        className="img-responsive"
                        src="assets/images/clean3.jpg"
                        alt=""
                    />
                    </div>
                    <div className="desc">
                    <h2>Office & commercial Cleaning Services</h2>
                    <p>
                        Whether it's conference rooms, community centres, club halls, waiting rooms, or offices, we have a customized cleaning solution. We offer a 100% satisfaction guarantee with our cleaning service, and our cleaners are bonded and insured by WCB.
                        <br/>
                        •	Cleaning outside of typical business hours<br/>
                        •	Daily, weekly or monthly cleaning frequencies<br/>
                        •	Emptying and disinfecting employee food areas (ie. staff fridge)

                    </p>
                    <div className="bg-overlay"></div>
                    <p>
                        <a className="learn-more" href="javascript:void(0)"
                        >
                        Whether it's conference rooms, community centres, club halls, waiting rooms, or offices, we have a customized cleaning solution. We offer a 100% satisfaction guarantee with our cleaning service, and our cleaners are bonded and insured by WCB.
                        <br/>
                        •	Cleaning outside of typical business hours<br/>
                        •	Daily, weekly or monthly cleaning frequencies<br/>
                        •	Emptying and disinfecting employee food areas (ie. staff fridge)
        </a>
                    </p>
                    </div>
                </div>
                
                </div>
                <div className="brand-dot row">
                    <div className="featured-item col-md-4 seo-service">
                        <div className="icon">
                        <img
                            className="img-responsive"
                            src="assets/images/clean4.jpg"
                            alt=""
                        />
                        </div>
                        <div className="desc">
                        <h2>Healthcare Cleaning Facility<br/><br/></h2>
                        <p>
                            Healthcare facilities such as clinics, doctor's offices, dental clinics, chiropractors, physiotherapy, sport medicine clinics, long-term care homes and more all have one thing in common: they require intensive medical cleaning services to ensure they meet all applicable standards of cleanliness. At Secure Force, we use the latest in hospital-grade ...
                        </p>
                        <div className="bg-overlay"></div>
                        <p>
                            <a className="learn-more" href="javascript:void(0)">
                                Healthcare facilities such as clinics, doctor’s offices, dental clinics, chiropractors, physiotherapy, sport medicine clinics, long-term care homes and more all have one thing in common: they require intensive medical cleaning services to ensure they meet all applicable standards of cleanliness. At Secure Force, we use the latest in hospital-grade disinfecting technology to effectively kill bacteria that can be potentially harmful in a healthcare environment. 
                            </a>
                        </p>
                        </div>
                    </div>
                    <div className="featured-item col-md-4 seo-service">
                        <div className="icon">
                        <img
                            className="img-responsive"
                            src="assets/images/clean5.jpg"
                            alt=""
                        />
                        </div>
                        <div className="desc">
                        <h2>Short-Term Rental & Airbnb Cleaning Services</h2>
                        <p>
                            Let us help you to prepare between reservations with our Airbnb cleaning services. We are fully licensed, bonded and insured with an A+ rating from the BBB. You can feel secure leaving your key with us, which is kept in a locked safe at our office. This eliminates the hassle of staying home to let our team in and your place can even stay maintained while you are out of town!
                        </p>
                        <div className="bg-overlay"></div>
                        <p>
                            <a className="learn-more" href="javascript:void(0)">
                                Let us help you to prepare between reservations with our Airbnb cleaning services. We are fully licensed, bonded and insured with an A+ rating from the BBB. You can feel secure leaving your key with us, which is kept in a locked safe at our office. This eliminates the hassle of staying home to let our team in and your place can even stay maintained while you are out of town!
                            </a>
                        </p>
                        </div>
                    </div>
                    <div className="featured-item col-md-4 seo-service">
                        <div className="icon">
                        <img
                            className="img-responsive"
                            src="assets/images/clean1.jpg"
                            alt=""
                        />
                        </div>
                        <div className="desc">
                        <h2>Post-Construction Cleaning Services</h2>
                        <p>
                            Final Post construction cleaning, using only 100% Natural Home Cleaners
        Once the dust has settled and you're almost ready to move in, contact Secure Care to schedule your post construction or post renovation cleaning service. We're experts in eco-friendly post construction and renovation cleanings, and promise to make your home shine.


                        </p>
                        <div className="bg-overlay"></div>
                        <p>
                            <a className="learn-more" href="javascript:void(0)">Final Post construction cleaning, using only 100% Natural Home Cleaners
                                Once the dust has settled and you're almost ready to move in, contact Secure Care to schedule your post construction or post renovation cleaning service. We're experts in eco-friendly post construction and renovation cleanings, and promise to make your home shine.
                                
                                </a>
                        </p>
                        </div>
                    </div>
                     <div className="featured-item col-md-12 mt-3 seo-service">
                        <div className="icon">
                        <img
                            className="img-responsive"
                            src="assets/images/clean8.jpg"
                            alt=""
                        />
                        </div>
                        <div className="desc">
                        <h2>Water Damage Restoration</h2>
                        <p>
                            Secure Care offers 24/7 emergency water damage restoration services in Toronto & the GTA. We have our partnered with Venture Restoration that has 15+ years of experience in flood & water damage restoration, our restoration experts respond quickly to minimize water damage & restore your property to its original state. 


                        </p>
                        <div className="bg-overlay"></div>
                        <p>
                            <a className="learn-more" href="javascript:void(0)">
                                Secure Care offers 24/7 emergency water damage restoration services in Toronto & the GTA. We have our partnered with Venture Restoration that has 15+ years of experience in flood & water damage restoration, our restoration experts respond quickly to minimize water damage & restore your property to its original state. 


                            </a>
                        </p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        
            <section className="section-padding">
            <div className="container">
                <div className="text-center mb-80">
                <h2 className="section-title text-uppercase">Our promise and values</h2>
                
                </div>
                <div className="row">
                <div className="col-md-4 mb-50">
                    <div
                    className="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div className="icon">
                        <i className="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div className="desc">
                        <h2 className="pt-4">Organic natural products</h2>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 mb-50">
                    <div
                    className="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div className="icon">
                        <i className="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div className="desc">
                        <h2 className="pt-4">100% client satisfaction</h2>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 mb-50">
                    <div
                    className="featured-item feature-icon icon-hover icon-hover-brand icon-outline"
                    >
                    <div className="icon">
                        <i className="material-icons colored brand-icon">&#xE5CA;</i>
                    </div>
                    <div className="desc">
                        <h2 className="pt-4">
                            Trusted and dependable
                            </h2>
                    </div>
                    </div>
                </div>
                
                </div>
                <div className="promo-box gray-bg border-box mt-100">
                <div className="promo-info">
                    <h2 className="text-extrabold text-uppercase font-25">
                    Contact for More Information
                    </h2>
                    
                </div>
                <div className="promo-btn">
                    <Link to="/contact"
                    className="btn btn-lg text-capitalize waves-effect waves-light"
                    >Contact Now</Link>
                </div>
                </div>
            </div>
            </section>

            <Footer/>
        </>
    )
}