import Footer from "../common/Footer";
import Menu from "../common/Menu";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import { useRef, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import '../../index.css'
export default function Carrer(){
    const form = useRef();
    const [name,setname] = useState("");
    const [email,setemail] = useState("");
    const [position,setposition] = useState("");
    const setXname = (e)=>{
        setname(e.target.value)
    }
    const setXemail = (e)=>{
        setemail(e.target.value)
    }
    const setXposition = (e)=>{
        setposition(e.target.value)
    }
    const fireemail = (e)=>{
        e.preventDefault();
        emailjs.sendForm('service_qw2aj7e', 'template_ikv8w3u', form.current, '9GgrSyHkmm6P2xDyT')
        .then((result) => {
            toast.success("For Submitted ! Team will contact you", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
                });
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        setname("");setemail("");setposition("");
    }
    return(
        <>
            <Menu/>
            
            
            <section className="container-fluid bg-white">
                <div className="row">
                    <div className="col-md-12 text-center p-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="text-left text-dark"><br/>Carrers Form</h2>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="container-fluid">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 my-5 py-5 box my-3">
                                <form ref={form}  name="contact-form" id="contactForm"onSubmit={fireemail}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h2>Application Form</h2>
                                            <p>Please Fill out the details</p>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="input-field">
                                                <input type="text" name="name" className="validate" id="name" value={name} onChange={setXname}/>
                                                <label for="name">Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="input-field">
                                                <label className="sr-only" for="email">Email</label>
                                                <input id="email" type="email" name="email" className="validate" value={email} onChange={setXemail}/>
                                                <label for="email" data-error="wrong" data-success="right">Email</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">

                                            <p>Position</p>
                                            <select className="form-control" value={position} onChange={setXposition}>
                                                <option>PSW</option>
                                                <option>RPN</option>
                                                <option>RN</option>
                                                <option>Others</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button type="submit" name="submit"
                                        className="waves-effect waves-light btn submit-button text-capitalize mt-30">Apply
                                        Now</button>
                                </form>
                            </div>
                            <div className="col-md-6 px-5 text-dark text-justify">
                                <div className="text-center">
                                    <h2 className="display-5 text-uppercase mt-5">
                                        Our Office</h2>
                                        <div className="col-lg-12">
                                            <div className="single_address"><i className="fa fa-map-marker"></i>
                                                <h4 style={{"color": "rgb(92, 145, 69)"}}>Our Address</h4>
                                                <p>1225 Kennedy road scarborough,Ontario,M1P4Y1</p>
                                            </div>
                                            <div className="single_address"><i className="fa fa-envelope"></i>
                                                <h4 style={{"color": "rgb(92, 145, 69)"}}>Send your message</h4>
                                                <p>info@securecare.ca</p>
                                            </div>
                                            <div className="single_address"><i className="fa fa-phone"></i>
                                                <h4 style={{"color": "rgb(92, 145, 69)"}}>Call us on</h4>
                                                <p>+1 4372270008</p>
                                            </div>
                                            <div className="single_address"><i className="fa fa-clock-o"></i>
                                                <h4 style={{"color": "rgb(92, 145, 69)"}}>Work Time</h4>
                                                <p>Mon - Sat: 09.00 - 18.00. </p>
                                            </div>
                                        </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

            <Footer/>
            <ToastContainer
            position="top-right"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
            theme="light"
            />
        </>
    )
}